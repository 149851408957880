import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ErrorImg from "../images/404.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 className="text-center text-4xl mb-4">Seite nicht gefunden!</h1>
    <div className="flex justify-center items-center">
      <img src={ErrorImg} alt="404 Error" />
    </div>
    
  </Layout>
)

export default NotFoundPage
